import { cloneDeep, isNil } from "lodash";
import { fetcher, fetchAsObservable } from "fetcher!sofe";
import { pluck, map } from "rxjs/operators";
import sanitizeRichTextHtml from "src/rich-text/bandicoot-html-sanitizer";
import { EmailAccount } from "src/types/email-account.types";

type TIncludes = "inbox" | "send" | "owner";
export type GetEmailAccountsArgs = {
  includes?: TIncludes;
  includeShared?: boolean;
};
export type GetEmailAccountsResponse = EmailAccount[];
export async function getEmailAccounts({
  includes,
  includeShared,
}: GetEmailAccountsArgs): Promise<GetEmailAccountsResponse> {
  const params = new URLSearchParams();
  if (includes) {
    params.append("includes", includes);
  }
  if (includeShared) {
    params.append("include_shared", includeShared.toString());
  }
  const res = await fetcher(`/email-accounts?${params}`);
  const sanitizedEmailAccounts = res["email-accounts"].map((account: EmailAccount) => {
    return account.signature
      ? { ...account, signature: sanitizeRichTextHtml(account.signature) }
      : { ...account, signature: "" };
  });
  return sanitizedEmailAccounts;
}

export function getEmailAccountsObs(includes: TIncludes) {
  return fetchAsObservable(`/email-accounts?includes=${includes}`).pipe(
    pluck("email-accounts"),
    map((accounts: EmailAccount[]) => {
      return accounts.map((account) => {
        return account.signature
          ? { ...account, signature: sanitizeRichTextHtml(account.signature) }
          : { ...account, signature: "" };
      });
    })
  );
}

export function createEmailAccount(newEmailAccount: any) {
  return fetchAsObservable(`/email-accounts`, {
    method: "post",
    body: { "email-accounts": newEmailAccount },
  }).pipe(pluck("email-accounts"));
}

export function putEmailAccount(emailAccount: EmailAccount, deleteEmails: boolean = false) {
  if (!emailAccount) throw Error("@putEmailAccount: emailAccount is undefined");

  const account = cloneDeep(emailAccount);
  delete account.connectionStatus;

  if (account && account.signature) {
    account.signature = sanitizeRichTextHtml(account.signature); // sanitizeRichTextHtml could be bypassed on the client and is not a guarantee for the server
  }

  return fetchAsObservable(`/email-accounts/${account.id}?delete-emails=${deleteEmails}`, {
    method: "put",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ "email-accounts": account }),
  }).pipe(pluck("email-accounts"));
}

export function deleteEmailAccount(emailAccountId: string, deleteEmails: any) {
  if (isNil(deleteEmails)) throw Error("deleteEmails is needed to remove an email account");

  return fetchAsObservable(`/email-accounts/${emailAccountId}?delete-emails=${deleteEmails}`, { method: "DELETE" });
}

export function patchUserPreferences(userId: string, communicationsPrefs: any) {
  return fetchAsObservable(`/wg/users/${userId}`, {
    method: "PATCH",
    body: {
      users: {
        preferences: {
          communications: communicationsPrefs,
        },
      },
    },
  }).pipe(pluck("users"));
}

export function getUsers() {
  return fetchAsObservable(`/wg/users?role=TeamMember`).pipe(pluck("users"));
}
