import { fetchAsObservable, fetcher } from "fetcher!sofe";
import { keyBy, pick, isEmpty } from "lodash";
import { EmailThread } from "./inbox.types";

function parseThreads(thread: any) {
  return isEmpty(thread.subject) ? { ...thread, subject: "No subject" } : thread;
}

export function getEmailThread(emailAccountId: string, threadId: string, fallbackSnapshot = false) {
  if (!emailAccountId) {
    throw new Error(`emailAccountId is required`);
  }

  if (!threadId) {
    throw new Error(`threadId is required`);
  }
  const fallbackParams = fallbackSnapshot ? "?fallback=snapshot" : "";
  const url = `/wg/email-accounts/${emailAccountId}/threads/${threadId}${fallbackParams}`;

  return fetchAsObservable(url);
}

export type GetEmailThreadsOpts = {
  page?: string;
  q?: string;
  filter?: string;
  search?: string;
};
export type GetEmailThreadsParams = {
  emailAccountId: string;
  opts?: GetEmailThreadsOpts;
};
export type GetEmailThreadsResponse = {
  pagination: {
    count: number;
    current_page: number;
    limit: number;
    total_page: number;
  };
  threads: EmailThread[];
};
export async function getEmailThreads({
  emailAccountId,
  opts = {},
}: GetEmailThreadsParams): Promise<GetEmailThreadsResponse> {
  if (!emailAccountId) {
    throw new Error(`emailAccountId is required`);
  }

  const params = {
    limit: "25",
    page: "0",
    ...opts,
  };

  const p = new URLSearchParams(params);
  const url = `/wg/email-accounts/${emailAccountId}/threads?${p.toString()}`;

  return await fetcher(url, {
    method: "GET",
  }).then((response: any) => ({
    ...response,
    threads: response.threads.map(parseThreads),
  }));
}

export function patchEmailThread(emailAccountId: string, threadId: string, payload: any) {
  const url = `/wg/email-accounts/${emailAccountId}/threads/${threadId}`;
  return fetchAsObservable(url, {
    method: "PATCH",
    body: payload,
  });
}

export function patchEmailThreads(emailAccountId: string, { threads, ...params }: any) {
  const url = `/wg/email-accounts/${emailAccountId}/threads`;

  const payload = {
    ...params,
    ids: keyBy(
      threads.map((thread: any) => pick(thread, ["id", "labels"])),
      "id"
    ),
  };

  return fetchAsObservable(url, {
    method: "PATCH",
    body: payload,
  });
}

export function bulkDeleteDrafts(emailAccountId: string, drafts: any) {
  const url = `/wg/email-accounts/${emailAccountId}/drafts-bulk-delete`;

  return fetchAsObservable(url, {
    method: "POST",
    body: { drafts },
  });
}

export type GetEmailThreadMessagesParams = {
  emailAccountId: string;
  threadId: string;
  opts?: {
    filter?: string;
    mark_read?: string;
  };
};
export type GetEmailThreadMessagesResponse = {
  messages: any[];
};

export function getEmailThreadMessages({
  emailAccountId,
  threadId,
  opts = {},
}: GetEmailThreadMessagesParams): Promise<GetEmailThreadMessagesResponse> {
  const params = {
    limit: "500",
    page: "0",
    ...opts,
  };

  if (!emailAccountId) {
    throw new Error(`emailAccountId is required`);
  }

  if (!threadId) {
    throw new Error(`threadId is required`);
  }

  const p = new URLSearchParams(params);
  const url = `/wg/email-accounts/${emailAccountId}/threads/${threadId}/messages?${p.toString()}`;

  return fetcher(url);
}

export function patchEmailStatus({
  emailAccountId,
  nylasObjectId,
  objectType,
  payload,
}: {
  emailAccountId: string;
  nylasObjectId: string;
  objectType: any;
  payload: any;
}) {
  if (!emailAccountId) {
    throw new Error(`emailAccountId is required`);
  }

  if (!nylasObjectId) {
    throw new Error(`Nylas thread or message ID is required`);
  }

  if (!objectType) {
    throw new Error(`Nylas object type is required (message|thread)`);
  }

  if (!payload) {
    throw new Error(`payload is required`);
  }

  const url = `/wg/email-accounts/${emailAccountId}/${objectType}/${nylasObjectId} `;

  return fetchAsObservable(url, {
    method: "PATCH",
    body: payload,
  });
}

export function getEmailUnreadCount(emailAccountId = 0) {
  if (!emailAccountId) {
    throw new Error(`emailAccountId is required`);
  }
  const url = `/wg/email-accounts/${emailAccountId}/unread-count`;

  return fetchAsObservable(url);
}

export function getCommentsUnreadCount() {
  return fetchAsObservable(`/wg/comments-unread`);
}

export function getAttachmentPreview(accountId: string, fileId: string) {
  if (!accountId) {
    throw new Error("cannot get a file preview without an email account ID");
  }

  if (!fileId) {
    throw new Error("cannot get a file preview without a file ID");
  }

  return fetchAsObservable(`wg/email-accounts/${accountId}/files/${fileId}/preview`);
}

export function getLabels(emailAccountId: string) {
  return fetchAsObservable(`/wg/email-accounts/${emailAccountId}/labels-folders`);
}

export function createLabel(emailAccountId: string, labelName: string) {
  return fetchAsObservable(`/wg/email-accounts/${emailAccountId}/labels-folders/${encodeURIComponent(labelName)}`, {
    method: "POST",
  });
}

export function patchLabel(emailAccountId: string, labelId: string, payload: any) {
  return fetchAsObservable(`/wg/email-accounts/${emailAccountId}/labels-folders/${labelId}`, {
    method: "PATCH",
    body: payload,
  });
}
