import { fetcher } from "fetcher!sofe";
import { EmailThread } from "../email.types";

type PatchThreadParams = {
  clientId: string;
  thread: any;
};
export async function patchThread({ clientId, thread }: PatchThreadParams) {
  const res = await fetcher(`/clients/${clientId}/email-threads/${clientId}`, {
    method: "PATCH",
    body: {
      "email-threads": thread,
    },
  });
  return res["email-threads"];
}

export type GetEmailThreadsParams = {
  clientId: string;
  page: number;
};
export type GetEmailThreadsResponse = {
  "email-threads": EmailThread[];
  meta: {
    clients: any[];
    paginator: {
      totalPages: number;
      totalThreads: number;
    };
  };
};
function getEmailThreads({ clientId, page }: GetEmailThreadsParams): Promise<GetEmailThreadsResponse> {
  return fetcher(`/clients/${clientId}/email-threads?page=${page}`);
}

export async function getEmailThreadsWithRetry(
  { clientId, page }: GetEmailThreadsParams,
  retries = 3
): Promise<GetEmailThreadsResponse> {
  /**
   * There are situations where the API returns no email threads, but the
   * backend is still importing emails. By retrying a few times, we can
   * minimize instances where the email account was recently connected, but
   * the emails haven't been imported yet.
   */
  const res = await getEmailThreads({ clientId, page });
  if (res["email-threads"].length === 0 && retries > 0) {
    await new Promise((resolve) => setTimeout(resolve, 400));
    return getEmailThreadsWithRetry({ clientId, page }, retries - 1);
  }
  return res;
}

export type GetEmailThreadParams = {
  clientId: string;
  threadId: string;
};
export type GetEmailThreadResponse = {
  "email-threads": EmailThread;
};
export async function getEmailThread({ clientId, threadId }: GetEmailThreadParams): Promise<GetEmailThreadResponse> {
  return fetcher(`/clients/${clientId}/email-threads/${threadId}`);
}

type DeleteEmailThreadParams = {
  clientId: string;
  threadId: string;
};
export async function deleteEmailThread({ clientId, threadId }: DeleteEmailThreadParams) {
  const res = await fetcher(`/clients/${clientId}/email-threads/${threadId}`, {
    method: "DELETE",
  });
  return res["email-threads"];
}

type CreateTempDocFromAttachmentParams = {
  tenantId: string;
  userId: string;
  attachment: any;
};
export function createTempDocFromAttachment({ tenantId, userId, attachment }: CreateTempDocFromAttachmentParams) {
  const body = {
    file: {
      copy: true,
      hidden: true,
      description: "",
      name: attachment.filename,
      parent_folder_id: "0",
      path_to_s3: `attachments/${tenantId}/${attachment.id}`,
      filesize: attachment.size,
      mimetype: attachment.content_type,
      visible_to_client: false,
      days_until_expiration: 1,
    },
  };

  return fetcher(`/api/docs/folders/USR${userId}/files`, {
    method: "POST",
    body,
  });
}
