import { queryOptions } from "@tanstack/react-query";
import { genQueries } from "src/react-query";
import { getEmailAccounts, GetEmailAccountsArgs } from "src/settings/email-accounts.resource";
import { EmailAccount } from "src/types/email-account.types";

export const emailAccountQueries = genQueries("email-account", ({ genKey }) => ({
  emailAccounts: (params: GetEmailAccountsArgs) =>
    queryOptions<EmailAccount[]>({
      queryKey: genKey("emailAccounts"),
      queryFn: () => getEmailAccounts(params),
      staleTime: 30_000,
    }),
}));
