import { fetcher } from "fetcher!sofe";
import { EmailMessage } from "../email.types";

export type GetEmailThreadMessagesParams = {
  clientId: string;
  threadId: string;
  pageNum: number;
};
export type GetEmailThreadMessagesResponse = {
  messages: EmailMessage[];
  meta: {
    paginator: {
      totalMessages: number;
      totalPages: number;
    };
  };
};
export function getEmailThreadMessages({
  clientId,
  threadId,
  pageNum,
}: GetEmailThreadMessagesParams): Promise<GetEmailThreadMessagesResponse> {
  return fetcher(`/clients/${clientId}/email-threads/${threadId}/messages?page=${pageNum}`);
}

export type SendEmailMessageParams = {
  clientId: string;
  draftId: string;
  threadId: string;
};
export type SendEmailMessageResponse = {
  messages: any[];
};
export function sendEmailMessage({
  clientId,
  draftId,
  threadId,
}: SendEmailMessageParams): Promise<SendEmailMessageResponse> {
  return fetcher(`/clients/${clientId}/email-threads/${threadId}/messages/${draftId}`, {
    method: "post",
  });
}
